<template>
  <el-table
    class="table-list-wrapper"
    ref="elTableRef"
    v-bind="tableProps"
    v-on="tableEvents"
    v-loading="loading"
  >
    <!-- 前插槽 -->
    <slot name="start"></slot>

    <!-- 根据配置 -->
    <template v-for="(item, index) in tableColumns">
      <!-- 插槽，在 config 的 columnProps 中配置 slot 的名字 -->
      <template v-if="item.columnProps.slot">
        <slot :name="item.columnProps.slot"></slot>
      </template>

      <!-- 无插槽，正常使用el-table-column渲染 -->
      <template v-else>
        <!-- 使用render进行自定义渲染 -->
        <el-table-column
          v-if="item.render"
          :key="`${item.columnProps.prop}_${index}`"
          v-bind="item.columnProps"
        >
          <template slot-scope="scope">
            <dynamic-component
              :custom-render="item.render"
              :source-data="scope.row"
            />
          </template>
        </el-table-column>

        <!-- 无render，正常渲染 -->
        <el-table-column
          v-else
          :key="`${item.columnProps.prop}_${index}`"
          v-bind="item.columnProps"
        >
          <!-- 如需嵌套，在配置中定义subColumns，与columnProps同级 -->
          <template v-if="item.subColumns">
            <template v-for="(subItem, subIndex) in item.subColumns">
              <el-table-column
                v-if="subItem.render"
                :key="`${subItem.columnProps.prop}_${subIndex}`"
                v-bind="subItem.columnProps"
              >
                <template slot-scope="scope">
                  <dynamic-component
                    :custom-render="subItem.render"
                    :source-data="scope.row"
                  />
                </template>
              </el-table-column>
              <el-table-column
                v-else
                :key="`${subItem.columnProps.prop}_${subIndex}`"
                v-bind="subItem.columnProps"
              />
            </template>
          </template>
        </el-table-column>
      </template>
    </template>

    <!-- 操作插槽 -->
    <slot name="actions"></slot>
  </el-table>
</template>

<script>
import DynamicComponent from './dynamic-component'

// 表格默认属性
const DEFAULT_TABLE_PROPS = {
  border: true,
  'header-align': 'center'
}
// 表格每一栏默认属性
const DEFAULT_COLUMN_PROPS = {
  align: 'center'
}

export default {
  name: 'TableList',
  props: {
    // 配置
    config: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    /**
     * el-table支持的所有Table属性
     */
    tableProps() {
      const { props = { data: [] } } = this.config
      return {
        ...DEFAULT_TABLE_PROPS,
        ...props
      }
    },
    /**
     * el-table支持的所有Table事件
     */
    tableEvents() {
      const { tableEvents = {} } = this.config
      return tableEvents
    },
    /**
     * el-table支持的所有Table-column属性
     */
    tableColumns() {
      const { tableColumns = [] } = this.config
      return tableColumns.map((item) => {
        this.$set(item, 'columnProps', {
          ...DEFAULT_COLUMN_PROPS,
          ...item.columnProps
        })
        return item
      })
    }
  },
  components: {
    DynamicComponent
  },
  activated() {
    this.$refs.elTableRef.doLayout()
  },
  methods: {
    // 获取el-table element
    getElTable() {
      return this.$refs.elTableRef.$el
    }
  }
}
</script>

<style lang="scss" scoped></style>
