export default {
  name: 'DynamicComponent',
  props: {
    // 自定义渲染
    customRender: {
      type: Function
    },
    // 数据
    sourceData: {
      type: Object,
      default: () => ({})
    }
  },
  render(h) {
    return this.customRender?.(h, this.sourceData) || null
  }
}
