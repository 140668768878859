import Cookies from 'js-cookie'
import router from '@/router'
import { clearLoginInfo } from '@/utils'
import isPlainObject from 'lodash/isPlainObject'
import { Message } from 'element-ui';
import { createRequest, addEncryInterceptors } from 'tasyee-utils'

const http = createRequest({
  baseURL: process.env.VUE_APP_BASE_URL,
})
const env = process.env.VUE_APP_MODE
addEncryInterceptors(http, env, env!='dev' && env!='test')
http.interceptors.request.use(config => {
  config.headers['Accept-Language'] = Cookies.get('language') || 'zh-CN'
  config.headers['token'] = Cookies.get('token') || ''
  const defaults = {}
  if (config.method === 'get') {
    config.params = {
      ...config.params,
      ...{ '_t': new Date().getTime() }
    }
  }
  if (isPlainObject(config.params)) {
    config.params = {
      ...defaults,
      ...config.params
    }
  }
  if (isPlainObject(config.data)) {
    config.data = {
      ...defaults,
      ...config.data
    }
  }
  return config
}, error => {
  return Promise.reject(error)
})

http.interceptors.response.use(response => {
  const {code, msg, data} = response
  if (code === 401 || data?.code === 10001) {
    clearLoginInfo()
    router.replace({ name: 'login' })
    return Promise.reject(msg)
  }
  return {data:response}
}, error => {
  console.log(error)
  Message({
    message: error.response?.data?.error || error?.message,
    type: 'error'
  });
  return Promise.reject(error)
})

export default http
